.brand-text {
  display: inline-block;
  width: 180px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.nav-icon {
  color: #636e79;
}
